/**
 * Created by hao.cheng on 2017/4/16.
 */
import React from 'react';
import $ from 'jquery'
import { Form, Icon, Input, Button, Checkbox, Row, Col, Tabs, notification, Badge, message } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchData, receiveData } from '@/action';
import { PwaInstaller } from '../../components/widget/index';
import { USER_INFO_SAVE } from '../../utils/storeInfo';
import Net from '../../utils/net/Net';
import { loginReq } from '../../action/login';
import axios from 'axios';
import { URL_login, URL_register } from "../../utils/net/Url";
import GlobalBadge from '@/components/GlobalBadge'
import Footer from './components/Footer'
import Register from './components/Register'
const FormItem = Form.Item;
const { TabPane } = Tabs;
class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            count: 60,
            intervalId: null,
            isRem: true,
            codeText: '验证码',
            codePromt: '',  //验证码频繁输入提示,
            registerStep: 0,
            veCode: null,   //注册验证码
            regPwd: null,   //注册密码
            regPhone: '',
            regUserName: '',
            flag: true,
            yzmflag: false,
            regForm: {
                company_name: '',
                province: '',
                city: '',
                country: '',
                address: '',
                phone: '',
                url: URL_register
            },
            activeKey: "1"
        }
    }
    // 获取验证码
    getVerificationCode = () => {
        let phoneNum = this.props.form.getFieldValue("regPhone")
        let reg = /^1[3456789]\d{9}$/
        if (phoneNum && phoneNum != '') {
            if (reg.test(phoneNum)) {
                $.ajax({
                    url: URL_register,
                    type: 'POST',
                    data: {
                        phone_num: phoneNum
                    },
                    // headers:{appToken:USER_INFO_GET()&&USER_INFO_GET().appToken||'','smsServiceType':'RESET_PASS_VERIFY_CODE',
                    //     'appClientType':'VENDOR','Content-Type':'application/json'},
                    success: (data) => {
                        console.log(data)
                        if (data.code == 1) {
                            this.setState({
                                flag: false,
                                yzmflag: true,
                                timer: 59
                            })
                            var n = setInterval(() => {
                                var j = this.state.timer;
                                this.setState({
                                    timer: j
                                })
                                this.state.timer--;
                                if (this.state.timer <= 0) {
                                    clearInterval(n);
                                    this.setState({
                                        flag: true,
                                        yzmflag: false
                                    })
                                }
                            }, 1000);
                            this.setState({
                                n: n
                            })
                        } else {
                            alert(data.messages)
                        }
                    },
                    error: (err) => {
                        if (err.status == '401') {
                            alert('登陆失效，请重新登录')
                            this.props.history.push('/login')
                        }
                    }
                })
            } else {
                alert('手机号码格式错误')
            }
        } else {
            alert('请输入手机号')
        }


    }
    componentWillMount() {
        const { dispatch } = this.props;
        dispatch(receiveData(null, 'login'))
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            // if (!err.userName && !err.password) {
                if(!err) {
                var dat = {
                    username: values.userName, password: values.password
                }
                $.ajax({
                    url: URL_login,
                    type: 'post',
                    data: dat,
                    headers: { "Content-Type": "application/x-www-form-urlencoded"},
                    success: (res) => {
                        if (res.code == 1) {
                            sessionStorage.setItem('phone',values.userName)
                            let { accountId, companyId, appToken } = res;
                            USER_INFO_SAVE({ accountId, companyId, appToken })
                            localStorage.setItem('messagesList', [1]);
                            if (localStorage.getItem('vehistateList')) {
                                localStorage.removeItem('groupId', '')
                                localStorage.removeItem('nicknames', '')
                                localStorage.removeItem('vehistateList', '')
                                localStorage.removeItem('partystateList', '')
                            }
                            var goeasy = new window.GoEasy({
                                appkey: 'BC-8ca9bcc36a664eafb152d717eca64743'
                            });
                            goeasy.subscribe({
                                channel: companyId || '',
                                onMessage: function (message) {
                                    // alert(message.content);
                                    if (localStorage.getItem('badgeCount')) {
                                        let count = localStorage.getItem('badgeCount')
                                        ++count
                                        localStorage.setItem('badgeCount', count)
                                    } else {
                                        localStorage.setItem('badgeCount', 1)
                                    }
                                    if ($('.ant-layout .ant-layout-content .global-badge').length <= 0) {
                                        $('.ant-layout .ant-layout-content').append(GlobalBadge)
                                        $('.ant-layout .ant-layout-content .global-badge').click(() => {
                                            window.location.hash = '#/app/salelist'
                                            localStorage.removeItem('badgeCount');
                                            $('.ant-layout .ant-layout-content .global-badge')[0].style.display = 'none'
                                        })
                                    } else {
                                        $('.ant-layout .ant-layout-content .global-badge')[0].innerHTML = `<span> 您有新的订单消息：${localStorage.getItem('badgeCount')}</span>`
                                        $('.ant-layout .ant-layout-content .global-badge')[0].style.display = 'block'
                                    }
                                    notification['success']({
                                        message: '温馨提示',
                                        description: message.content,
                                        duration: 3
                                    })
                                }
                            });
                            this.props.history.push('/app/dashboard')
                        } else {
                            message.info(res.messages)
                        }

                    },
                    error: (err) => {
                        if (err.status === 405) {
                            const res = err.responseJSON
                            message.info(res.resultMessage)
                        }
                    }
                })
                /* this.setState({
                     isRem:values.remember
                 })
                 const {dispatch} = this.props;
                 dispatch(loginReq({username:values.userName,password:values.password}));

                 return
                 console.log('Received values of form: ', values);*/
            }
        });
    };
    // 注册开始
    stepSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (err == null || (err && !err.regUserName && !err.veCode && !err.regPwd && !err.regPhone)) {
                let userName = values.regUserName
                let veCode = values.veCode;
                let regPhone = values.regPhone
                let regPwd = values.regPwd
                if (userName != '' && regPhone != '' && veCode != '' && regPwd != '') {
                    $.ajax({
                        url: URL_register,
                        type: 'POST',
                        data: {
                            code: veCode,
                            phone_num: regPhone,
                            password: regPwd,
                            userName
                        },
                        success: (res) => {
                            if (res.code == 1) {
                                this.setState({
                                    registerStep: 1,
                                    regPhone
                                })
                            } else {
                                alert(res.messages)
                            }
                        }
                    })
                }
            }
        })
    }
    regHasErrors = (fieldsError) => {
        if (fieldsError) {
            return Object.keys(fieldsError).some(field => fieldsError[field])
        }
    }
    // 注册功能搜索框
    handleSearch = (val) => {
        this.setState({
            regForm: {
                componyName: val,
                province: val,
                city: val,
                address: val,
                phone: val
            }
        })
    }
    // 获取手机输入框的值
    getPhone = (e) => {
        this.setState({
            regPhone: e.target.value
        })
    }
    // 注册搜索自动补全
    selectedCompony = (val) => {
        $.ajax({
            url: URL_register,
            type: "POST",
            data: {
                join_company: val
            },
            success: (res) => {
                let { regForm } = this.state
                regForm = res
                regForm.url = URL_register
                this.setState({
                    regForm
                })
            },
            error: (err) => {
                alert('服务器或网络异常')
            }
        })
    }
    // 注册返回
    regBack = () => {
        this.clearValidate()
        this.setState({
            registerStep: 0
        })
    }
    // 校验公司
    vaildCompany = (regForm) => {
        let url = URL_register
        regForm.url = url
        this.setState({
            regForm
        })
        delete regForm.id
        this.props.form.validateFields((err, values) => {
            if (err == null || (err && !err.regForm)) {
                let { regPhone } = this.state
                $.ajax({
                    url,
                    type: 'POST',
                    data: {
                        regForm: JSON.stringify(regForm),
                        regPhone
                    },
                    success: (res) => {
                        if (res.code == 1) {
                            message.success(res.messages)
                            this.goLogin()
                        } else {
                            message.error(res.messages)
                        }
                    }
                })
            }
        })
    }
    goLogin = () => {
        this.clearValidate()
        this.setState({
            activeKey: "1",
            registerStep: 0
        })

    }
    // 标签页改变
    changeTab = (key) => {
        this.clearValidate()
        let { registerStep } = this.state
        this.setState({
            activeKey: key
        })
        if (key == 2 && registerStep === 1) {
            this.setState({
                registerStep: 0
            })
        }
    }
    // 清除表单验证效果
    clearValidate = () => {
        this.props.form.validateFields((err) => {
            if (err) {
                this.props.form.resetFields();
            }
        })
    }
    componentWillUnmount() {
        localStorage.setItem('stateList', '')
        localStorage.setItem('groupId', '')
        localStorage.setItem('nicknames', '')
    }
    componentDidMount() {
        var newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.src = "https://cdn-hangzhou.goeasy.io/goeasy.js";
        $('body').append(newScript);
    }
    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const veCodeError = isFieldTouched('veCode') && getFieldError('veCode');
        const regPwdError = isFieldTouched('regPwd') && getFieldError('regPwd');
        const regPhoneError = isFieldTouched('regPhone') && getFieldError('regPhone');
        return (
            <div className="login" style={{ background: '#ececec' }}>
                <div className='page-inner'>
                    <nav className="gtco-nav">
                        <div className="gtco-container">
                            <Row type='flex' justify='center'>
                                <Col xm={4} xs={12}>
                                    <div className="gtco-logo"><a href="/">保配车联-汽配网络询价系统 baopei.wang</a></div>
                                </Col>
                                <Col xs={8}></Col>
                            </Row>
                        </div>
                    </nav>
                    <header className="gtco-header gtco-cover">
                        <div className="overlay"></div>
                        <div className="gtco-container">
                            <Row type='flex' justify='center'>
                                <Col md={20} >
                                    <div className='container-wrapper'>
                                        <Row type='flex' align='middle'>
                                            <Col md={13} className='mt-text'>
                                                <span className='intro-text-small'>Welcome to baopei.wang</span>
                                                <h1>欢迎使用 保配车联 汽配网络询价系统</h1>
                                            </Col>
                                            <Col md={{ span: 9, push: 2 }}>
                                                <div className="login-form" >
                                                    <Tabs type='card' defaultActiveKey='1' onTabClick={this.changeTab} activeKey={this.state.activeKey}>
                                                        <TabPane tab='登录' key='1'>
                                                            <div className="login-logo">
                                                                <span>用户登录</span>
                                                            </div>
                                                            <Form onSubmit={this.handleSubmit} style={{ maxWidth: '300px' }}>
                                                                <FormItem>
                                                                    {getFieldDecorator('userName', {
                                                                        rules: [{ required: true, message: '请输入用户名!' }],
                                                                    })(
                                                                        <Input size='large' prefix={<Icon type="user" style={{ fontSize: 14 }} />} placeholder="请输入用户名" />
                                                                    )}
                                                                </FormItem>
                                                                <FormItem>
                                                                    {getFieldDecorator('password', {
                                                                        rules: [{ required: true, message: '请输入密码!' }],
                                                                    })(
                                                                        <Input size='large' prefix={<Icon type="lock" style={{ fontSize: 14 }} />} type="password" placeholder="请输入密码" />
                                                                    )}
                                                                </FormItem>
                                                                <FormItem>
                                                                    {getFieldDecorator('remember', {
                                                                        valuePropName: 'checked',
                                                                        initialValue: true,
                                                                    })(
                                                                        <Checkbox>记住我</Checkbox>
                                                                    )}

                                                                    <Button size='large' type="primary" htmlType="submit" style={{ width: '100%' }}>
                                                                        登录
                                                                    </Button>
                                                                    {/*<div style={{display: 'flex', justifyContent: 'space-between'}}>*/}
                                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                        {/*<span onClick={()=>{
                                                                        this.props.history.push('./reg')
                                                                    }}>新用户注册</span>*/}
                                                                        <span style={{ cursor: 'pointer' }} onClick={() => {
                                                                            this.props.history.push('./fogetPwd')
                                                                        }}>忘记密码</span>
                                                                    </div>
                                                                </FormItem>
                                                            </Form>
                                                        </TabPane>
                                                        <TabPane tab='注册' key='2'>
                                                            <div style={{textAlign: 'center'}}>
                                                                <img
                                                                    style={{ width: 200 }}
                                                                    src={require('../../style/imgs/qr-code.png')} alt="" />
                                                                <p style={{marginTop: 20}}>关注保配车联-汽配网络询价系统微信公众号，联系我们。</p>
                                                                <p>万能客服小姐姐和Ai机器人回答您的一切问题。</p>
                                                            </div>
                                                        </TabPane>
                                                        {/* <TabPane tab='注册' key='2'>
                                                            {this.state.registerStep === 0 &&
                                                                <>
                                                                    <div className="login-logo">
                                                                        <span>用户注册</span>
                                                                    </div>

                                                                    <Form onSubmit={this.stepSubmit} style={{ maxWidth: '300px' }}>
                                                                        <FormItem >
                                                                            {getFieldDecorator('regUserName', {
                                                                                rules: [{ required: true, message: '请输入用户名!' }]
                                                                            })(
                                                                                <Input size='large' prefix={<Icon type="user" style={{ fontSize: 14 }} />} type="regUserName" placeholder="请输入用户名" />
                                                                            )}
                                                                        </FormItem>
                                                                        <FormItem >
                                                                            {getFieldDecorator('regPhone', {
                                                                                rules: [{ required: true, message: '请输入手机号!' }]
                                                                            })(
                                                                                <Input size='large' prefix={<Icon type="phone" style={{ fontSize: 14 }} />} type="regPwd" placeholder="请输入需要注册的手机号码" />
                                                                            )}
                                                                        </FormItem>
                                                                        <FormItem >
                                                                            <Row gutter={8}>
                                                                                <Col span={13}>
                                                                                    {getFieldDecorator('veCode', {
                                                                                        rules: [{ required: true, message: '验证码不能为空!' }],
                                                                                    })(
                                                                                        <Input
                                                                                            size='large'
                                                                                            type='veCode'
                                                                                            prefix={<Icon type="user"
                                                                                                style={{ fontSize: 14 }} />}
                                                                                            placeholder="验证码"
                                                                                        />
                                                                                    )}
                                                                                </Col>
                                                                                <Col span={11}>
                                                                                    {this.state.flag ?
                                                                                        <Button
                                                                                            type='primary'
                                                                                            onClick={this.getVerificationCode}>
                                                                                            获取验证码
                                                                                    </Button>
                                                                                        :
                                                                                        <span style={{ display: 'block' }}>
                                                                                            <Button type='primary'>
                                                                                                ({this.state.timer}s)重新获取
                                                                                     </Button>
                                                                                        </span>}
                                                                                </Col>
                                                                            </Row>
                                                                        </FormItem>
                                                                        <FormItem >
                                                                            {getFieldDecorator('regPwd', {
                                                                                rules: [{ required: true, message: '请输入密码!' }],
                                                                                initialValue: 123456
                                                                            })(
                                                                                <Input.Password size='large' prefix={<Icon type="lock" style={{ fontSize: 14 }} />} type="regPwd" placeholder="请输入密码" disabled/>
                                                                            )}
                                                                        </FormItem>
                                                                        <FormItem>
                                                                            <Button
                                                                                size='large'
                                                                                type="primary"
                                                                                htmlType="submit"
                                                                                style={{ width: '100%' }}>
                                                                                下一步
                                                                    </Button>
                                                                        </FormItem>
                                                                    </Form>
                                                                </>
                                                            }
                                                            {this.state.registerStep === 1 &&
                                                                <Register
                                                                    form={this.props.form}
                                                                    regForm={this.state.regForm}
                                                                    handleSearch={this.handleSearch}
                                                                    selectedCompony={this.selectedCompony}
                                                                    regPhone={this.state.regPhone}
                                                                    regBack={this.regBack}
                                                                    vaildCompany={this.vaildCompany}
                                                                    goLogin={this.goLogin}
                                                                />
                                                            }
                                                        </TabPane> */}
                                                    </Tabs>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </header>
                    <Footer />
                </div>
                <div className="go-top">
                    <a href="#" className='js-gotop'>
                        <Icon type="arrow-up" />
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToPorps = state => ({
    loginInfo: state.loginReducer.loginInfo
});

export default connect(mapStateToPorps)(Form.create()(Login));
