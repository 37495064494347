import React, {
    Component, Suspense
} from 'react';
import {
    Layout,
    notification,
    Icon
} from 'antd';
import SiderCustom from './components/SiderCustom';
import HeaderCustom from './components/HeaderCustom';
import ErrorBoundary from './components/ErrorBoundary';
import { USER_INFO_GET, USER_INFO_REMOVE } from "@/utils/storeInfo";
import Notify from '@wcjiang/notify';
import { Spin } from 'antd'
import {
    receiveData
} from './action';
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import Routes from './routes';
import {
    ThemePicker
} from './components/widget';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
const {
    Content,
    Footer
} = Layout;

class App extends Component {
    state = {
        collapsed: false,
    };
    componentWillMount() {
        const {
            receiveData
        } = this.props;
        const user = JSON.parse(localStorage.getItem('user'));
        user && receiveData(user, 'auth');
        // receiveData({a: 213}, 'auth');
        // fetchData({funcName: 'admin', stateName: 'auth'});
        this.getClientWidth();
        window.onresize = () => {
            this.getClientWidth();
        }
        if(!USER_INFO_GET()) {
            this.props.history.push('/login')
        }
    }
    componentDidMount () {
        this.initWebSocket()
        let times = 50
        this.interval = setInterval(() => {
            times = times - 1
            if (times < 0) {
                console.log(2121)
                let obj = {
                    id: 0
                }
                this.websock.send(JSON.stringify(obj))
                times = 50
            }
        }, 1000)
    }
    componentWillUnmount() {
        USER_INFO_REMOVE()
    }
    initWebSocket(){ //初始化weosocket
        // const wsuri = 'wss://comm.partai.cn/wss/echo/';//ws地址
        const wsuri = 'ws://148.70.137.178:12345/echo/';//ws地址
        this.websock = new WebSocket(wsuri);
        this.websock.onopen = this.websocketonopen;
        this.websock.onerror = this.websocketonerror;
        this.websock.onmessage = this.websocketonmessage;
        this.websock.onclose = this.websocketclose;
    }
    websocketonopen(e) {
        console.log(e);
        console.log("WebSocket连接成功");
    }
    websocketonerror(e) { //错误
        console.log(e);
        console.log("WebSocket连接发生错误");
    }
    websocketonmessage(e){ //数据接收
        // console.log(JSON.parse(decodeURIComponent(e.data)))
        const notify = new Notify({
            audio:{
                file: []
            }
        })
        // notify.setFavicon(1)
        notify.faviconClear()
        // if (JSON.parse(decodeURIComponent(e.data)).id === USER_INFO_GET().companyId) {
        // let resyult = JSON.parse(decodeURIComponent(e.data)).id.indexOf(USER_INFO_GET().companyId) !== -1
        // console.log(JSON.parse(decodeURIComponent(e.data)).id)
        if (JSON.parse(decodeURIComponent(e.data)).id !== 0 && JSON.parse(decodeURIComponent(e.data)).id !== undefined) {
            let result  = JSON.parse(decodeURIComponent(e.data)).id.split(';')
            // console.log(result)
            // console.log(JSON.parse(decodeURIComponent(e.data)))
            if (!result.includes(USER_INFO_GET().companyId)) {
                return
            }
            let arr = []
            const messagesList = localStorage.getItem('messagesList').split(',')
            messagesList.map((item)=>{
                arr.push(item)
            })
            if (arr.includes(JSON.parse(decodeURIComponent(e.data)).created_at)) {
                // arr.push(JSON.parse(decodeURIComponent(e.data)).created_at)
            } else {
                arr.push(JSON.parse(decodeURIComponent(e.data)).created_at)
            }
            localStorage.setItem('messagesList', arr);
            // console.log(arr)
            notify.faviconClear()
             if (JSON.parse(decodeURIComponent(e.data)).source === '智配宝') {
                 let times = 10
                 this.interval = setInterval(() => {
                     times = times - 1
                     if (times < 0) {
                         notify.setTitle(false) // 播放动画
                     } else if (times > 8) {
                         notify.setTitle()
                         notify.stopPlay()
                         notify.setTitle(true) // 播放动画
                         notify.setTitle('您收到一个新的询价人询价单，请注意及时报价。') // 闪烁新标题
                         // notify.setTitle() // 清除闪烁 显示原来的标题
                         notify.setURL('https://enquiry-voice-1259007386.cos.ap-shanghai.myqcloud.com/insurance.mp3')
                         notify.player();
                     }
                 }, 1000)
                 // const audio= new Audio("https://enquiry-voice-1259007386.cos.ap-shanghai.myqcloud.com/workshop.mp3");
                 // audio.pause()
                 // audio.load()
                 // audio.play()

                 // console.log(123)
                 notification.warning({
                     message: '询价提醒',
                     placement:'bottomRight',
                     description:
                         '您收到一个新的询价人询价单，请注意及时报价。',
                 })
             }
             if (JSON.parse(decodeURIComponent(e.data)).source === '云险') {
                 let times = 10
                 this.interval = setInterval(() => {
                     times = times - 1
                     if (times < 0) {
                         notify.setTitle(false) // 播放动画
                     } else if (times > 8) {
                         notify.setTitle()
                         notify.stopPlay()
                         notify.setTitle(true) // 播放动画
                         notify.setTitle('您收到一个新的保险询价单，请注意及时报价。') // 闪烁新标题
                         // notify.setTitle() // 清除闪烁 显示原来的标题
                         notify.setURL('https://music-1301104573.cos.ap-chengdu.myqcloud.com/new_enquiry.mp3')
                         notify.player();
                     }
                 }, 1000)
                 // const audio= new Audio("https://enquiry-voice-1259007386.cos.ap-shanghai.myqcloud.com/insurance.mp3");
                 // audio.pause()
                 // audio.load()
                 // audio.play()
                 // console.log(123)
                 notification.warning({
                     message: '询价提醒',
                     placement:'bottomRight',
                     description:
                         '您收到一个新的询价单，请注意及时报价。',
                 })
             }
            if (JSON.parse(decodeURIComponent(e.data)).source === '透明修') {
                let times = 10
                this.interval = setInterval(() => {
                    times = times - 1
                    if (times < 0) {
                        notify.setTitle(false) // 播放动画
                    } else if (times > 8) {
                        notify.setTitle()
                        notify.stopPlay()
                        notify.setTitle(true) // 播放动画
                        notify.setTitle('您收到一个新的订单，请注意查收。') // 闪烁新标题
                        // notify.setTitle() // 清除闪烁 显示原来的标题
                        notify.setURL('https://enquiry-voice-1259007386.cos.ap-shanghai.myqcloud.com/order.mp3')
                        notify.player();
                    }
                }, 1000)
                // const audio= new Audio("https://enquiry-voice-1259007386.cos.ap-shanghai.myqcloud.com/insurance.mp3");
                // audio.pause()
                // audio.load()
                // audio.play()
                // console.log(123)
                notification.warning({
                    message: '询价提醒',
                    placement:'bottomRight',
                    description:
                        '您收到一个新的订单，请注意查收。',
                })
            }
        } else {
            notify.faviconClear()
            // console.log(11111111)
            // notification.warning({
            //     message: '测试',
            //     placement:'bottomRight',
            //     description:
            //         '您收到一个新的测试询价单，请注意及时报价。',
            // })
            // console.log(321)
            // const notify = new Notify({
            //     audio:{
            //         // 可以使用数组传多种格式的声音文件
            //         file: ['msg.mp4','msg.mp3','msg.wav']
            //         // 下面也是可以的哦
            //         // file: 'msg.mp4'
            //     }
            // })
            // notify.setTitle()
            // notify.stopPlay()
            // notify.setTitle(true) // 播放动画
            // notify.setTitle('新标题1111111111111111111111111') // 闪烁新标题
            // // notify.setTitle() // 清除闪烁 显示原来的标题
            // notify.setURL('https://enquiry-voice-1259007386.cos.ap-shanghai.myqcloud.com/insurance.mp3')
            // notify.player();
        }
        // console.log(32132132131);
    }
    websocketsend(agentData){//数据发送
        console.log(123123)
        this.websock.send('12312321');
    }
    getClientWidth = () => { // 获取当前浏览器宽度并设置responsive管理响应式
        const {
            receiveData
        } = this.props;
        const clientWidth = window.innerWidth;
        console.log(clientWidth);
        receiveData({
            isMobile: clientWidth <= 992
        }, 'responsive');
    };
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    render() {
        const {
            auth,
            responsive
        } = this.props;
        return (
            <Layout>
            {!responsive.data.isMobile && <SiderCustom collapsed={this.state.collapsed} />}
        {/*<ThemePicker />*/}
        <Layout style={{flexDirection: 'column'}}>
        <HeaderCustom toggle={this.toggle} collapsed={this.state.collapsed} user={auth.data || {}}/>
        <Content style={{ margin: '0 16px', overflow: 'initial', flex: '1 1 0' }}>
             <ErrorBoundary>
              <Routes auth={auth} />
             </ErrorBoundary>
        </Content>
        <Footer style={{ paddingBottom:0,paddingTop:12,position: "fixed",bottom: 0,width: '100%' }}>
            {/*<marquee behavior="behavior" style={{ width: '100%'}} loop="2">您有新的询价单，请及时报价，报价约越时，成交率越高哦~</marquee>*/}
        {/* 智配宝Ai   {new Date().getFullYear()} */}
    </Footer>
        </Layout>

        {/* {
                    responsive.data.isMobile && (   // 手机端对滚动很慢的处理
                        <style>
                        {`
                            #root{
                                height: auto;
                            }
                        `}
                        </style>
                    )
                } */}
    </Layout>
    );
    }
}

const mapStateToProps = state => {
    const {
        auth = {
            data: {}
        },
        responsive = {
            data: {}
        }

    } = state.httpData;
    return {
        auth,
        responsive
    };
};
const mapDispatchToProps = dispatch => ({
    receiveData: bindActionCreators(receiveData, dispatch)
});

export default connect(state=>({
    auth:{data:{},...state.httpData.auth},
    responsive:{data:{},...state.httpData.responsive}
}), mapDispatchToProps)(App);
