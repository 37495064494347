export default {

    menus:[
        {
            key: '/app/dashboard', title: '首页',icon: 'home',component: 'Dashboard'
        },
        {
            key: '/app/wuSun', title: '物损报价',icon: 'home',component: 'WuSun'
        },
        {
            key: '/app/insurance', title: '报价销售', icon: 'rocket',
            subs: [
                {key: '/app/insurance', title: '询价单', icon: 'bars', component:'SmartInsurance'},
                // {key: '/app/salelist', title: '同行调货订单', icon: 'bars', component:'SaleList'},
                // {key: '/app/surveyorder', title: '查勘员订单', icon: 'bars', component:'SurveyorOrder'},
                // {key: '/app/repairorder', title: '询价人订单', icon: 'bars', component:'RepairOrder'},
                // {key: '/app/transparantorder', title: '管理后台订单', icon: 'bars', component:'TransparantOrder'},
                {key: '/app/DRPOrder', title: '订单', icon: 'bars', component:'DRPOrder'}
            ]
        },
        // {
        //     key: '/app/smartquotation', title: '智能报价',icon: 'rocket',
        //     subs:[
        //         {key: '/app/smartquotation', title: '智能报价', icon: 'rocket',component:'Smartquotation'},
        //         {key: '/app/goodsaddition', title: '添加商品', icon: 'form', component:'Goodsaddition'},
        //         {key: '/app/goodsmanage', title: '商品设置', icon: 'setting', component:'GoodsManage'},
        //         {key: '/app/offerlist', title: '报价记录', icon: 'bars', component:'Offerlist'},
        //     ]
        // },
        // {
        //     key: '/app/epc', title: 'EPC', icon: 'rocket',
        //     subs: [
        //         {key: '/app/epc', title: 'EPC', icon: 'rocket', component:'SmartEpc'}
        //     ]
        // },
        // {
        //     key: '/app/stockshare', title: '库存共享',icon: 'bars',component: 'Test'
        // },
        // {
        //     key: '/app/mystock', title: '库存共享', icon: 'bars',
        //     subs:[
        //         {key: '/app/mystock', title: '查看共享', icon: 'eye', component:'Mystock'},
        //         {key: '/app/stockview', title: '发布共享', icon: 'form', component:'Goodsaddition'},
        //         {key: '/app/transferlist', title: '采购记录', icon: 'bars', component:'TransferList'},
        //         {key: '/app/stocklist', title: '共享设置', icon: 'setting', component:'Stocklist'},
        //     ]

        // },
        // {
        //     key: '/app/specialview', title: '特价清仓', icon: 'bars',
        //     subs: [
        //         {key: '/app/specialview', title: '查看特价', icon: 'eye', component:'SpecialOffer'},
        //         {key: '/app/releaseprice', title: '发布特价', icon: 'form', component:'Goodsaddition'},
        //         {key: '/app/specialmanage', title: '特价设置', icon: 'setting', component:'SpecialManage'},
        //     ]
        // },
        // {
        //     key: '/app/goodsmanage', title: '商品管理', icon: 'bars',
        //     subs:[

        //     ]

        // },
        // {
        //     key:'/app/AutoRepairDatabase',title:'汽修地图',icon:'global',
        //     subs:[
        //         {key:'/app/AutoRepairDatabase',title:'汽修数据库', icon: 'global', component:'AutoRepairDatabase'},
        //         {key:'/app/MyCollection',title:'我的收藏', icon: 'heart', component:'MyCollection'}
        //         // {key:'/app/SummaryStatistics',title:'汇总统计',component:'SummaryStatistics'}
        //     ]
        // },
        /*{
            key: '/app/intellige', title: '智能EPC', icon: 'bars',
            subs:[
                {key: '/app/intellige', title: '选车型', component:'CheckVehicle'},
            ]

        },*/
        // {
        //     key: '/app/offerlist', title: '报价记录', icon: 'bars', component:'Offerlist',
        //     subs:[
        //         {key: '/app/offerlist', title: '报价记录', component:'Offerlist'},
        //     ]
        // },
        // {
        //     key: '/app/salelist', title: '销售记录', icon: 'bars',
        //     subs:[
        //         // {key: '/app/retailorder', title: '询价人订单', component:'RetailOrder'},
        //         {key: '/app/salelist', title: '同行销售', icon: 'bars', component:'SaleList'}
        //     ]
        // },
        // {
        //     key: '/app/transferlist', title: '采购记录', icon: 'bars',
        //     subs:[
        //         {key: '/app/transferlist', title: '同行采购', icon: 'bars', component:'TransferList'},
        //     ]
        // },
        // {
        //     key: '/app/salelist', title: '对外销售', icon: 'bars',
        //     subs:[
        //         {key: '/app/salelist', title: '对外销售', component:'SaleList'},
        //     ]
        // },
        // {
        //     key: '/app/ordersmanage', title: '订单管理', icon: 'appstore',
        //     subs:[
        //         {key: '/app/ordersmanage', title: '订单管理', component:'OrdersManage',},
        //         // {key: '/app/orderdetail', title: '订单详情', component:'OrderDetail',},
        //     ]
        // },
        {
            key: '/app/dataCenter', title: '数据中心', icon: 'bars',
            subs: [
                { key: '/app/dataCenter', title: '业绩数据', icon: 'bars', component: 'dataCenter' },
            ]
        },
        {

            key: '/app/user/person', title: '我的账户', icon: 'user',
            subs: [
                { key: '/app/user/person', title: '个人设置', icon: 'user', component: 'PersonSetting'},
                { key: '/app/user/company', title: '公司设置', icon: 'bank', component: 'CompanySetting'},
                // { key: '/app/user/account', title: '账户续费', component: 'AccountSetting'},
                { key: '/app/user/pwd', title: '密码修改', icon: 'setting',component: 'PwdSetting'}
            ],
        }/*,
        { key: '/app/test', title: '测试', icon: 'mobile', component: 'TEST' },
        { key: '/app/dashboard/index', title: '首页', icon: 'mobile', component: 'Dashboard' },

        {
            key: '/app/ui', title: 'UI', icon: 'scan',
            subs: [
                { key: '/app/ui/buttons', title: '按钮', component: 'Buttons'},
                { key: '/app/ui/icons', title: '图标', component: 'Icons'},
                { key: '/app/ui/spins', title: '加载中', component: 'Spins'},
                { key: '/app/ui/modals', title: '对话框', component: 'Modals'},
                { key: '/app/ui/notifications', title: '通知提醒框', component: 'Notifications'},
                { key: '/app/ui/tabs', title: '标签页', component: 'Tabs'},
                { key: '/app/ui/banners', title: '轮播图', component: 'Banners'},
                { key: '/app/ui/wysiwyg', title: '富文本', component: 'WysiwygBundle'},
                { key: '/app/ui/drags', title: '拖拽', component: 'Drags'},
                { key: '/app/ui/gallery', title: '画廊', component: 'Gallery'},
                { key: '/app/ui/map', title: '地图', component: 'MapUi'},
            ],
        },
        {
            key: '/app/animation', title: '动画', icon: 'rocket',
            subs: [
                { key: '/app/animation/basicAnimations', title: '基础动画', component: 'BasicAnimations'},
                { key: '/app/animation/exampleAnimations', title: '动画案例', component: 'ExampleAnimations'},
            ],
        },
        {
            key: '/app/table', title: '表格', icon: 'copy',
            subs: [
                { key: '/app/table/basicTable', title: '基础表格', component: 'BasicTable'},
                { key: '/app/table/advancedTable', title: '高级表格', component: 'AdvancedTable'},
                { key: '/app/table/asynchronousTable', title: '异步表格', component: 'AsynchronousTable'},
            ],
        },
        {
            key: '/app/form', title: '表单', icon: 'edit',
            subs: [
                { key: '/app/form/basicForm', title: '基础表单', component: 'BasicForm'},
            ],
        },
        {
            key: '/app/chart', title: '图表', icon: 'area-chart',
            subs: [
                { key: '/app/chart/echarts', title: 'echarts', component: 'Echarts' },
                { key: '/app/chart/recharts', title: 'recharts', component: 'Recharts' },
            ],
        },
        {
            key: '/subs4', title: '页面', icon: 'switcher',
            subs: [
                { key: '/login', title: '登录' },
                { key: '/404', title: '404' },
            ],
        },
        {
            key: '/app/auth', title: '权限管理', icon: 'safety',
            subs: [
                { key: '/app/auth/basic', title: '基础演示', component: 'AuthBasic' },
                { key: '/app/auth/routerEnter', title: '路由拦截', component: 'RouterEnter', auth: 'auth/testPage' },
            ],
        },
        {
            key: '/app/cssModule', title: 'cssModule', icon: 'star', component: 'Cssmodule'
        },
        {
            key: '/app/extension', title: '功能扩展', icon: 'bars',
            subs: [
                { key: '/app/extension/queryParams', title: '问号形式参数', component: 'QueryParams', query: '?param1=1&param2=2' },
            ],
        },*/
    ],


    menus1: [ // 菜单相关路由
        { key: '/app/dashboard/index', title: '首页', icon: 'mobile', component: 'Dashboard' },
        {
            key: '/app/ui', title: 'UI', icon: 'scan',
            subs: [
                { key: '/app/ui/buttons', title: '按钮', component: 'Buttons'},
                { key: '/app/ui/icons', title: '图标', component: 'Icons'},
                { key: '/app/ui/spins', title: '加载中', component: 'Spins'},
                { key: '/app/ui/modals', title: '对话框', component: 'Modals'},
                { key: '/app/ui/notifications', title: '通知提醒框', component: 'Notifications'},
                { key: '/app/ui/tabs', title: '标签页', component: 'Tabs'},
                { key: '/app/ui/banners', title: '轮播图', component: 'Banners'},
                { key: '/app/ui/wysiwyg', title: '富文本', component: 'WysiwygBundle'},
                { key: '/app/ui/drags', title: '拖拽', component: 'Drags'},
                { key: '/app/ui/gallery', title: '画廊', component: 'Gallery'},
                { key: '/app/ui/map', title: '地图', component: 'MapUi'},
            ],
        },
        {
            key: '/app/animation', title: '动画', icon: 'rocket',
            subs: [
                { key: '/app/animation/basicAnimations', title: '基础动画', component: 'BasicAnimations'},
                { key: '/app/animation/exampleAnimations', title: '动画案例', component: 'ExampleAnimations'},
            ],
        },
        {
            key: '/app/table', title: '表格', icon: 'copy',
            subs: [
                { key: '/app/table/basicTable', title: '基础表格', component: 'BasicTable'},
                { key: '/app/table/advancedTable', title: '高级表格', component: 'AdvancedTable'},
                { key: '/app/table/asynchronousTable', title: '异步表格', component: 'AsynchronousTable'},
            ],
        },
        {
            key: '/app/form', title: '表单', icon: 'edit',
            subs: [
                { key: '/app/form/basicForm', title: '基础表单', component: 'BasicForm'},
            ],
        },
        {
            key: '/app/chart', title: '图表', icon: 'area-chart',
            subs: [
                { key: '/app/chart/echarts', title: 'echarts', component: 'Echarts' },
                { key: '/app/chart/recharts', title: 'recharts', component: 'Recharts' },
            ],
        },
        {
            key: '/subs4', title: '页面', icon: 'switcher',
            subs: [
                { key: '/lolianxiwomengin', title: '登录' },
                { key: '/404', title: '404' },
            ],
        },
        {
            key: '/app/auth', title: '权限管理', icon: 'safety',
            subs: [
                { key: '/app/auth/basic', title: '基础演示', component: 'AuthBasic' },
                { key: '/app/auth/routerEnter', title: '路由拦截', component: 'RouterEnter', auth: 'auth/testPage' },
            ],
        },
        {
            key: '/app/cssModule', title: 'cssModule', icon: 'star', component: 'Cssmodule'
        },
        {
            key: '/app/extension', title: '功能扩展', icon: 'bars',
            subs: [
                { key: '/app/extension/queryParams', title: '问号形式参数', component: 'QueryParams', query: '?param1=1&param2=2' },
            ],
        },
    ],
    others: [
        {key: '/app/goodsupdate/:id', title: '商品添加', component:'Goodupdate'},
        {key: '/app/orderdetail', title: '订单详情', component:'OrderDetail'},
        {key: '/app/offerdetail', title: '报价详情', component:'Offerdetail'},
        {key: '/app/shareorderdetail/:id', title: '同行调货详情', component:'ShareOrderDetail'},  //同行调货详情
        {key: '/app/transferdetail/:id', title: '报价详情', component:'Transferdetail'},
        {key: '/app/addgoods', title: '商品添加', component:'AddGoods'},
        {key: '/app/carts', title: '购物车', component:'Carts'},
        {key: '/app/ordersettlement', title: '购物车', component:'Ordersettlement'},
        {key: '/app/comfirorder', title: '购物车', component:'Comfirorder'},
    ] // 非菜单相关路由
}
