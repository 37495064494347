import React, { Component } from 'react'
import { Row, Col, Icon } from 'antd'
export default class Footer extends Component {
    render() {
        return (
            <footer className='gtco-footer'>
            <div className="gtco-container">
                <Row className='footer-desc'>
                    <Col md={8}>
                        <div className="gtco-widget">
                            <h3>关于保配车联-汽配网络询价系统 </h3>
                            <p>保配车联-汽配网络询价Ai将机器学习、大数据等技术与汽车配件的行业特点、应用场景深入融合，为汽配交易的经销商、询价人和保险公司提供智能化的SaaS系统和数据服务。</p>
                        </div>
                    </Col>
                    <Col md={{span: 8, push: 2}}>
                        <div className="gtco-widget">
                            <h3>链接</h3>
                            <ul>
                                <li>
                                    <a href='#'>智能Ai汽配报价SaaS系统</a>
                                </li>
                                {/*<li>*/}
                                {/*    <a href='#'>智能24h无人销售机器人</a>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <a href='#'>智能汽配库存共享平台</a>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <a href='#'>97万汽修厂数据与位置库</a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="gtco-widget">
                            <h3>联系我们</h3>
                            <ul>
                                <li><Icon type="phone" />+86 189 0193 0235</li>
                                <li><Icon type="home" />上海市嘉定工业区叶城路912号 </li>
                                <li><Icon type="mail" />info@baopei.wang</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className='copyright' type='flex' justify='center'>
                    <Col md={24}>
                        <p className="pull-left">
                            <small className="block">Copyright © 2018-2023.保配车联 All rights reserved</small>
                        </p>
                        <p className="pull-right">
                            <small className="block">苏ICP备2022044600号</small>
                        </p>
                    </Col>
                </Row>
            </div>
        </footer>
        )
    }
}
